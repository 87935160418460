.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.query-input {
  width: 80%;
  margin: 20px 0;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  background-color: #61dafb;
  color: #282c34;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.more-info {
  margin-top: 20px;
}

.book-cover {
  width: 150px;
  height: auto;
}
